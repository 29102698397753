import React, { useEffect, useState } from "react";
import axios from "axios";
import { Typography } from "antd";

const { Title, Paragraph } = Typography;

const APP_URL = process.env.REACT_APP_API_URL;
function Rules() {
    const [content, setContent] = useState("");

    useEffect(() => {
        const fetchContent = async () => {
            try {
                const response = await axios.get(`${APP_URL}/api/rules`);
                setContent(response.data[0].content);
            } catch (err) {
                console.error(err);
            }
        };

        fetchContent();
    }, []);

    return (
        <div>
            <Title>Rules and Regulations</Title>
            <Paragraph>{content}</Paragraph>

            <Title>Category Criterias</Title>
            <Title></Title>
        </div>
    );
}

export default Rules;
