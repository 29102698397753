import React, { useEffect, useState } from "react";
import axios from "axios";
import artistImg from "../img/nayeon-2024-jype.jpg";
import {
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Container,
    Grid,
    ImageList,
    Typography,
} from "@mui/material";

import HeroMsg from "../components/common/HeroMsg";
import {
    FacebookOutlined,
    Instagram,
    MusicNote,
    MusicNoteRounded,
    Twitter,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
const { Title, Paragraph } = Typography;

function Artists() {
    const APP_URL = process.env.REACT_APP_API_URL;
    const [Artists, setArtists] = useState([]);

    useEffect(() => {
        const fetchContent = async () => {
            try {
                const response = await axios.get(`${APP_URL}/api/Artists`);
                setArtists(response.data);
            } catch (err) {
                console.error(err);
            }
        };

        fetchContent();
    }, []);

    const title = "BMA Registered Artists";
    const subtitle = "Observe registered BMA Artists";
    return (
        <>
            <HeroMsg title={title} subtitle={subtitle} />
            <Container>
                <Grid
                    container
                    maxWidth={"lg"}
                    sx={{
                        alignContent: "center",
                        justifyContent: "center",
                        justifyItems: "center",
                    }}
                    spacing={1}
                >
                    {Artists.map((artist) => (
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Card>
                                <CardActionArea>
                                    <CardMedia
                                        component="img"
                                        image={`${APP_URL}${artist.profile_image_url}`}
                                    />
                                    <CardContent>
                                        <Typography
                                            gutterBottom
                                            variant="h6"
                                            component="div"
                                        >
                                            {artist.stage_name}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="text.primary"
                                        >
                                            {artist.birth_date}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="text.secondary"
                                        >
                                            {artist.bio}
                                        </Typography>

                                        <Link
                                            to={artist.social_media.apple_music}
                                        >
                                            <FacebookOutlined />
                                        </Link>
                                        <Link to={artist.social_media.twitter}>
                                            <Twitter />
                                        </Link>
                                        <Link to={artist.social_media.spotify}>
                                            <MusicNoteRounded />
                                        </Link>
                                        <Link
                                            to={artist.social_media.instagram}
                                        >
                                            <Instagram />
                                        </Link>
                                        <Link to={artist.social_media.facebook}>
                                            <FacebookOutlined />
                                        </Link>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </>
    );
}

export default Artists;
