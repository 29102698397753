import React, { useEffect } from "react";
import {
    BrowserRouter as Router,
    Route,
    Routes,
    ScrollRestoration,
    useLocation,
} from "react-router-dom";
import AppLayout from "./components/Layout";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import NominatedArtists from "./pages/NominatedArtists";
import Categories from "./components/Categories";
import Rules from "./components/Rules";
import Sponsor from "./components/Sponsor";
import News from "./pages/News";
import NewsArticle from "./components/NewsArticle";
import Artists from "./pages/Artists";
import ussd from "./pages/ussd";
import { ConfigProvider } from "antd";
import { ThemeProvider } from "@emotion/react";
import theme from "./components/common/theme";
import { CssBaseline } from "@mui/material";
import { ScrollToTop } from "./components/common/ScrollTop";
import Poll, { Vote } from "./components/Vote";
import Ussd from "./pages/ussd";

function App() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Router>
                <ScrollToTop />
                <AppLayout>
                    <Routes>
                        <Route path="/" exact element={<Home />} />
                        {/* <Route path="/about" element={<About />} /> */}
                        <Route path="/contact" element={<Contact />} />
                        {/* <Route
                            path="/nominated-artists"
                            element={<NominatedArtists />}
                        /> */}
                        {/* <Route path="/categories" element={<Categories />} />
                        <Route path="/rules" element={<Rules />} />
                        <Route path="/artists" element={<Artists />} />
                        <Route path="/sponsors" element={<Sponsor />} />
                        <Route path="/news" element={<News />} /> */}
                        <Route path="/ussd" element={<Ussd />} />
                        <Route path="/vote" element={<Vote />} />
                        {/* <Route path="/news/:id" element={<NewsArticle />} /> */}
                    </Routes>
                </AppLayout>
            </Router>
        </ThemeProvider>
    );
}

export default App;
