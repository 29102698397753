import React from "react";
import { Box, Link, Typography } from "@mui/material";
import {
    FacebookFilled,
    InstagramFilled,
    TwitterCircleFilled,
} from "@ant-design/icons";

export const SocialMedia = ({ socialLinks }) => {
    return (
        <Box
            sx={{
                color: "primary.main",
                textAlign: "center",
            }}
        >
            <Typography variant="h6" component={"h6"}>
                {socialLinks.title}
            </Typography>
            {socialLinks.socialMedia.map((social, index) => (
                <Link
                    key={index}
                    href={social.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ marginLeft: 2, color: "primary.light" }}
                >
                    {social.name === "Facebook" && <FacebookFilled />}
                    {social.name === "Twitter" && <TwitterCircleFilled />}
                    {social.name === "Instagram" && <InstagramFilled />}
                </Link>
            ))}
        </Box>
    );
};
