// HeroSection.js
import React from "react";
import { Container, Grid, Typography } from "@mui/material";
// import trophy from "../img/sponsorLogos/trophy.png";

const HomePageHero = ({ data }) => {
    return (
        <Grid
            item
            xs={12}
            sx={{
                paddingY: 10,
                paddingX: 5,
                backgroundColor: "secondary.main",
                background:
                    "linear-gradient(to right bottom, '#070924', '#cba328')",
                display: "flex",
                flexDirection: "row",
                // clipPath:
                //     "polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%)",
            }}
        >
            {/* <img src={trophy} height="100px" /> */}
            <Container sx={{ textAlign: "center" }}>
                <Typography color="primary.main" variant="h2">
                    {data.title}
                </Typography>
                <Typography
                    variant="subtitle1"
                    color="primary.dark"
                    sx={{ opacity: "0.8" }}
                >
                    {data.subtitle}
                </Typography>
            </Container>
            {/* <img src={trophy} height="100px" /> */}
        </Grid>
    );
};

export default HomePageHero;
