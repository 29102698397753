import React from "react";
import {
    Container,
    Grid,
    TextField,
    Button,
    Typography,
    Card,
    CardContent,
    Box,
} from "@mui/material";
import { Phone, Email, LocationOn } from "@mui/icons-material";
import { SocialMedia } from "./common/SocialMedia";

export const ContactForm = () => {
    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        console.log({
            name: data.get("name"),
            email: data.get("email"),
            subject: data.get("subject"),
            message: data.get("message"),
        });
        // Handle form submission (e.g., send data to server)
    };

    return (
        <Grid item margin={2}>
            <Box>
                <CardContent>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="name"
                                    label="Name"
                                    name="name"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email"
                                    name="email"
                                    type="email"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="subject"
                                    label="Subject"
                                    name="subject"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="message"
                                    label="Message"
                                    name="message"
                                    multiline
                                    rows={4}
                                />
                            </Grid>
                            <Grid item xs={12} align="center">
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                    fullWidth
                                >
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </Box>
        </Grid>
    );
};

export const ContactInfo = () => {
    return (
        <Grid container margin={2}>
            <Card sx={{ mt: 4 }}>
                <CardContent>
                    <Typography
                        variant="h6"
                        component="h2"
                        align="center"
                        gutterBottom
                    >
                        Contact Information
                    </Typography>
                    <Box display="flex" justifyContent="center" mb={2}>
                        <Phone sx={{ mr: 1 }} />
                        <Typography>+257 79 21 20 46 </Typography>
                    </Box>
                    <Box display="flex" justifyContent="center" mb={2}>
                        <Email sx={{ mr: 1 }} />
                        <Typography>info@bujamusicawards.com</Typography>
                    </Box>
                    <Box display="flex" justifyContent="center" mb={2}>
                        <LocationOn sx={{ mr: 1 }} />
                        <Typography>
                            Bujumbura Mairie, Bujumbura, Burundi
                        </Typography>
                    </Box>
                    <SocialMedia />
                </CardContent>
            </Card>
        </Grid>
    );
};
