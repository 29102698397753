import React, { useEffect, useState } from "react";
import axios from "axios";
import { List, Card, Col, Row } from "antd";
import { Link } from "react-router-dom";
import { Content } from "antd/es/layout/layout";
import NewsCarousel from "../components/NewsCarousel";
import NewsArticle from "../components/NewsArticle";
import HeroSection from "../components/common/HeroSection";
import NewsLandingPage from "../components/common/newsLandingPage";

const { Meta } = Card;

const APP_URL = process.env.REACT_APP_API_URL;

function News() {
    const [news, setNews] = useState([]);

    useEffect(() => {
        const fetchNews = async () => {
            try {
                const response = await axios.get(`${APP_URL}/api/news`);
                setNews(response.data);
            } catch (err) {
                console.error(err);
            }
        };

        fetchNews();
    }, []);

    return (
        <>
            <NewsLandingPage />
        </>
    );
}

export default News;
