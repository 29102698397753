import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import img from "../img/nayeon-2024-jype.jpg";
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Divider,
    Grid,
    Typography,
} from "@mui/material";

const { Meta } = Card;

const APP_URL = process.env.REACT_APP_API_URL;

function NewsCarousel() {
    const imageStyle = {
        backgroundSize: "cover",
        borderRadius: "10px",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "10%",
    };
    const [news, setNews] = useState([]);

    useEffect(() => {
        const fetchNews = async () => {
            try {
                const response = await axios.get(
                    `${APP_URL}/api/news`
                );
                setNews(response.data);
            } catch (err) {
                console.error(err);
            }
        };
        fetchNews();
    }, []);

    return (
        <>
            {/* <Grid spacing={1}>
                <Carousel autoplay>
                    {news.slice(0, 3).map((item) => (
                        <div key={item.id}>
                            <img src={img} alt={item.title} width="100%" />
                        </div>
                    ))}
                </Carousel>
            </Grid> */}
            <Grid container spacing={1}>
                {news.map((item) => (
                    <Grid item xs={12} sm={6} md={4}>
                        <Card key={item.id}>
                            <CardMedia
                                image={img}
                                title="green iguana"
                                component="img"
                            />
                            <CardContent>
                                <Typography
                                    gutterBottom
                                    variant="h6"
                                    component="div"
                                >
                                    {item.title}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                ></Typography>

                                {item.content.substring(0, 100) + "..."}
                            </CardContent>
                            <CardActions>
                                <Button size="small">Share</Button>
                                <Link to={`/news/${item.id}`}>
                                    <Button size="small">Learn More</Button>
                                </Link>
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
                <Divider />
            </Grid>
        </>
    );
}

export default NewsCarousel;
