import styled from "@emotion/styled";
import { AppBar, Button, Paper } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
// import { animateScroll as scroll } from "react-scroll";
import TopNavigation from "./TopNavigation";

const AnimatedPaper = styled(Paper)({
    position: "sticky",
    top: "20px",
    // left: 0,
    // right: 0,
    zIndex: 1000, // Adjust the z-index as needed
    transition: "padding 0.5s ease-in-out",
});

const NavBar = () => {
    const [isFixed, setIsFixed] = useState(false);
    const initialScrollPosition = useRef(0);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPosition = window.scrollY;
            const scrollThreshold = 50;

            setIsFixed(
                currentScrollPosition - initialScrollPosition.current >
                    scrollThreshold
            );
        };

        initialScrollPosition.current = window.scrollY;

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <AnimatedPaper
            elevation={1}
            style={{
                // position: isFixed ? "fixed" : "relative",
                top: isFixed ? "0" : "0",
                padding: isFixed ? 0 : 10,
                // Use a negative value to hide the navbar

                // backgroundColor: "primary.main",
                backgroundColor: "#070924",
            }}
        >
            <TopNavigation />
        </AnimatedPaper>
    );
};

export default NavBar;
