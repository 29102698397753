import React, { useState } from "react";
import AppRoutes from "./AppRoutes";
import {
    Box,
    Drawer,
    Grid,
    IconButton,
    List,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import logo from "../../img/logo.webp";
import { Link } from "react-router-dom";
import { MenuOutlined } from "@ant-design/icons";
import { CloseCircleOutlined } from "@ant-design/icons";

const TopNavigation = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [isDrawerOpen, setDrawerOpen] = useState(false);
    const toggleDrawer = () => {
        setDrawerOpen(!isDrawerOpen);
    };

    return (
        <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            sx={{ maxWidth: "md", margin: "auto" }}
            color={"white"}
        >
            <Grid item textAlign="center">
                <img
                    src={logo}
                    alt="Buja Music Awards"
                    style={{
                        width: isSmallScreen ? "10vw" : "5vw",
                        margin: "auto 0",
                    }}
                />
            </Grid>

            <Grid item textAlign="center" alignItems="center">
                {isSmallScreen ? (
                    <Typography variant="h6" margin={1}>
                        Buja Music Awards
                    </Typography>
                ) : null}
            </Grid>

            <Grid
                item
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                }}
            >
                {isSmallScreen ? (
                    <IconButton
                        sx={{ color: "inherit" }}
                        onClick={toggleDrawer}
                    >
                        <MenuOutlined />
                    </IconButton>
                ) : (
                    AppRoutes.map((link, index) =>
                        link.mainMenu ? (
                            <Typography
                                key={index}
                                style={{
                                    padding: "10px",
                                }}
                            >
                                <Link to={link.path} style={{ color: "white" }}>
                                    {link.label}
                                </Link>
                            </Typography>
                        ) : null
                    )
                )}

                <Drawer
                    anchor="right"
                    open={isDrawerOpen}
                    onClose={toggleDrawer}
                    elevation={10}
                    PaperProps={{
                        sx: {
                            minWidth: "100vw",
                            backgroundColor: "primary.main",
                        },
                    }}
                >
                    <Link onClick={toggleDrawer}>
                        <CloseCircleOutlined
                            sx={{
                                color: "secondary.main",
                                padding: 1,
                                opacity: "0.5",
                            }}
                        />
                    </Link>
                    {/* <MobileMenu /> */}

                    <Grid container>
                        {/* Logo / Header of the Mobile Menu */}

                        <Grid item xs={12}>
                            <Box sx={{ textAlign: "center" }}>
                                <img
                                    src={logo}
                                    alt="Buja Music Awards"
                                    style={{
                                        width: "30vw",
                                    }}
                                />
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs={11}
                            sx={{
                                margin: "0 auto",
                            }}
                        >
                            <List
                                sx={{
                                    margin: "auto 0",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                }}
                            >
                                {AppRoutes.map((link, index) =>
                                    link.mobileMenu ? (
                                        <Link
                                            to={link.path}
                                            onClick={toggleDrawer}
                                            key={index}
                                        >
                                            {link.label}
                                        </Link>
                                    ) : null
                                )}
                            </List>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                variant="h6"
                                sx={{
                                    margin: "auto",
                                }}
                            >
                                {/* <SocialMedia /> */}
                            </Typography>
                        </Grid>
                    </Grid>
                </Drawer>
            </Grid>
        </Grid>
    );
};
export default TopNavigation;
