import React, { useEffect, useState } from "react";
import axios from "axios";
import { List, Card } from "antd";

const APP_URL = process.env.REACT_APP_API_URL;
function Categories() {
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axios.get(`${APP_URL}/api/categories`);
                setCategories(response.data);
            } catch (err) {
                console.error(err);
            }
        };

        fetchCategories();
    }, []);

    return (
        <List
            grid={{ gutter: 16, column: 3 }}
            dataSource={categories}
            renderItem={(category) => (
                <List.Item>
                    <Card title={category.name}>{category.description}</Card>
                </List.Item>
            )}
        />
    );
}

export default Categories;
