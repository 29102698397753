import React, { useState } from "react";
import Poll from "./Poll";
import PasswordForm from "./PasswordForm";
import { Grid } from "@mui/material";

export const Vote = () => {
    return (
        <Poll /> // Your main voting component
    );
};
