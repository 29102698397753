import React, { useEffect, useState } from "react";
import HomePageHero from "../components/common/HomePageHero";
import {
    Banner,
    Features,
    GetInvolved,
    OurMission,
    Testimonials,
} from "../components/common/Components";
import axios from "axios";
import { LoadingOutlined } from "@ant-design/icons";

const APP_URL = process.env.REACT_APP_API_URL;

const Home = () => {
    const [content, setContent] = useState([]);

    const [error, setError] = useState(false);

    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        const fetchContent = async () => {
            try {
                const response = await axios.get(`${APP_URL}/api/homepage`);
                setContent(response.data[0]);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchContent();
    }, []);

    if (isLoading) {
        return <LoadingOutlined />;
    }
    if (error) {
        return <div>Error occured fetching data</div>;
    }
    return (
        <>
            <HomePageHero data={content.hero} />
            {/* {content.map((item) => console.log(item))} */}
            <Features />
            <Banner
                title={content.callToAction.title}
                message={content.callToAction.subtitle}
            />

            {/* <BujaMusicAwards /> */}
            {/* <UpcomingEvents /> */}
            <OurMission />
            <GetInvolved />
            {/* <LandingPage /> */}

            <Testimonials />
        </>
    );
};
export default Home;
