import React, { useEffect, useState } from "react";
import axios from "axios";
import { Typography } from "antd";
import ContactSection, {
    ContactForm,
    ContactInfo,
} from "../components/contactSection";
import { SocialMedia } from "../components/common/SocialMedia";
import {
    Box,
    Card,
    CardContent,
    CardMedia,
    CircularProgress,
    Container,
    Grid,
} from "@mui/material";
import { Email, LocationOn, Phone } from "@mui/icons-material";
import { Banner } from "../components/common/Components";
import logo from "../img/logo.webp";

const { Title, Paragraph } = Typography;

const APP_URL = process.env.REACT_APP_API_URL;

function Contact() {
    const [footer, setfooter] = useState({});
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchContent = async () => {
            try {
                const response = await axios.get(`${APP_URL}/api/footer`);
                setfooter(response.data[0]);
            } catch (err) {
                setError(true);
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        fetchContent();
    }, []);

    if (loading) {
        return <CircularProgress />;
    }

    if (error) {
        return (
            <Typography variant="h6" color="error">
                Failed to load data: {error.message}
            </Typography>
        );
    }
    return (
        <Grid container justifyContent={"space-around"}>
            <Banner
                title={"Contact us Today!"}
                message={
                    "Get in touch with Buja Music Awards team today, we are here to help"
                }
            />
            <Container maxWidth={"lg"}>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={8}>
                        <ContactForm />
                    </Grid>
                    <Grid item xs={12} md={4} sx={{ my: "auto" }}>
                        <Box>
                            <CardMedia
                                component="img"
                                alt={"Buja Music Awards Official Logo"}
                                width="200"
                                image={logo}
                            />
                            <CardContent>
                                <Typography variant="subtitle2">
                                    <Phone />{" "}
                                    {footer.sections[2].contactInfo.phone}
                                </Typography>
                                <Typography variant="subtitle2">
                                    <Email />{" "}
                                    {footer.sections[2].contactInfo.email}
                                </Typography>
                                <Typography variant="subtitle2">
                                    <LocationOn />
                                    {footer.sections[2].contactInfo.address}
                                </Typography>
                                <SocialMedia socialLinks={footer.sections[1]} />
                            </CardContent>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Grid>
    );
}

export default Contact;
