import React, { useState, useEffect } from "react";
import axios from "axios";
import {
    Card,
    Button,
    Radio,
    Typography,
    Input,
    Checkbox,
    message,
    Form,
} from "antd";
import {
    Box,
    CardContent,
    CardMedia,
    Container,
    Divider,
    Grid,
    Paper,
} from "@mui/material";
import Cookies from "js-cookie";
import { PhoneOutlined, MailOutlined, UserOutlined } from "@ant-design/icons";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
    Banner,
    BannerTwo,
    GetInvolved,
    PrimaryStrip,
    Strip,
} from "./common/Components";

const { Title } = Typography;
const { Item } = Form;

const APP_URL = process.env.REACT_APP_API_URL;

const Poll = () => {
    // State variables
    const [polls, setPolls] = useState([]);
    const [selectedPoll, setSelectedPoll] = useState(null);
    const [selectedOptions, setSelectedOptions] = useState({});
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [loading, setLoading] = useState(false);
    const [userInfo, setUserInfo] = useState({
        name: "",
        email: "",
        phone: "",
        agreeToContact: false,
    });
    const [userInfoSubmitted, setUserInfoSubmitted] = useState(false);
    const [voted, setVoted] = useState(false);

    // Fetch polls on component mount
    useEffect(() => {
        const fetchPolls = async () => {
            try {
                const response = await axios.get(`${APP_URL}/api/polls`);
                const pollsData = response.data.map((poll) => ({
                    ...poll,
                    alreadyVoted: Cookies.get(`voted_${poll._id}`)
                        ? true
                        : false,
                }));
                setPolls(pollsData);
            } catch (err) {
                console.error("Error fetching polls:", err);
            }
        };

        fetchPolls();
    }, []);

    // Select a poll to vote
    const handlePollSelect = async (pollId) => {
        const selected = polls.find((poll) => poll._id === pollId);
        if (selected.alreadyVoted) {
            message.error("You have already voted");
            return;
        }
        if (Cookies.get(`voted_${pollId}`)) {
            message.error("You have already voted");
            return;
        }
        try {
            const response = await axios.get(`${APP_URL}/api/polls/${pollId}`);
            setSelectedPoll(response.data);
            setCurrentQuestionIndex(0);
            setSelectedOptions({});
            setUserInfoSubmitted(false);
            setVoted(false);
        } catch (err) {
            console.error("Error selecting poll:", err);
        }
    };

    // Move to the next question
    const handleNextQuestion = () => {
        if (selectedOptions[selectedPoll.questions[currentQuestionIndex]._id]) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        } else {
            message.error("Please select an option before proceeding.");
        }
    };

    // Move to the previous question
    const handlePreviousQuestion = () => {
        setCurrentQuestionIndex(currentQuestionIndex - 1);
    };

    // Submit user information for voting
    const handleUserInfoSubmit = async () => {
        if (!userInfo.name || !userInfo.email || !userInfo.phone) {
            message.error("Please fill in all the fields.");
            return;
        }
        if (!userInfo.agreeToContact) {
            message.error("You must agree to be contacted.");
            return;
        }

        // Check if user has already voted using email verification
        try {
            const response = await axios.get(
                `${APP_URL}/api/checkVotedByEmail/${userInfo.email}`
            );
            const responseMobile = await axios.get(
                `${APP_URL}/api/checkVotedByNumber/${userInfo.phone}`
            );

            if (response.data.voted || responseMobile.data.voted) {
                //setVoted(true); // User has already voted
                Cookies.set(`voted_${selectedPoll._id}`, true, {
                    expires: 365,
                });
                message.error("Sorry, You have already voted in this poll.");
                //setSelectedPoll(null);
                return;
            }
        } catch (err) {
            console.error("Error verifying vote status:", err);
            message.error(
                "An error occurred while verifying your vote status."
            );
            return;
        }
        setVoted(false);
        setUserInfoSubmitted(true);
    };

    // Submit the poll answers
    const handleSubmitPoll = async () => {
        if (selectedOptions[selectedPoll.questions[currentQuestionIndex]._id]) {
            setLoading(true);
            try {
                const votes = Object.keys(selectedOptions).map(
                    (questionId) => ({
                        questionId,
                        optionId: selectedOptions[questionId],
                    })
                );
                // sending data to backend server for processing
                await axios.post(
                    `${APP_URL}/api/polls/${selectedPoll._id}/submit`,
                    { votes, userInfo }
                );

                message.success("Your votes have been submitted successfully!");
                Cookies.set(`voted_${selectedPoll._id}`, true, {
                    expires: 365,
                });

                setVoted(true);
                // setSelectedPoll(null);
            } catch (err) {
                console.error("Error submitting votes:", err);
                message.error("An error occurred while submitting your votes.");
            } finally {
                setLoading(false);
            }
        } else {
            message.error("Please select an option before submitting.");
        }
    };

    // Handle option selection for a question
    const handleOptionChange = (questionId, optionId) => {
        setSelectedOptions({
            ...selectedOptions,
            [questionId]: optionId,
        });
    };

    // Render the component
    return (
        <Grid container justifyContent={"space-around"}>
            <Grid item xs={12}>
                <Banner title={"BMA 2024 Online Voting System"} />
            </Grid>
            {!selectedPoll ? (
                // if no poll selected, display all available polls for voting
                <Grid
                    item
                    xs={12}
                    md={10}
                    lg={6}
                    textAlign={"center"}
                    alignSelf={"space-around"}
                    marginTop={2}
                >
                    <Paper elevation={16}>
                        {polls.map((poll) => (
                            <Card
                                key={poll._id}
                                title={poll.title}
                                style={{ marginBottom: 16 }}
                            >
                                <div>{poll.description}</div>
                                {poll.alreadyVoted ||
                                Cookies.get(`voted_${poll._id}`) ? (
                                    <p style={{ color: "red", marginTop: 8 }}>
                                        You have already voted in this poll.
                                    </p>
                                ) : (
                                    <Box
                                        sx={{
                                            py: 1,
                                            bgcolor: "secondary.light",
                                            color: "primary.contrastText",
                                            textAlign: "center",
                                        }}
                                    >
                                        <Container>
                                            <Button
                                                onClick={() =>
                                                    handlePollSelect(poll._id)
                                                }
                                                style={
                                                    {
                                                        //marginBottom: 16,
                                                        //backgroundColor: "gold",
                                                    }
                                                }
                                            >
                                                VOTE NOW
                                            </Button>
                                        </Container>
                                    </Box>
                                )}
                            </Card>
                        ))}
                    </Paper>
                </Grid>
            ) : (
                // once a poll has been selected and saved in local storage, load the for to submit voter data to kick off the vote
                <Grid
                    container
                    style={{ marginBottom: 10 }}
                    justifyContent={"space-around"}
                >
                    <Grid
                        item
                        xs={12}
                        justifyContent={"space-around"}
                        sx={{
                            py: 1,
                            my: 1,
                        }}
                    >
                        <Box
                            sx={{
                                py: 1,
                                bgcolor: "primary.main",
                                color: "primary.contrastText",
                                textAlign: "center",
                            }}
                        >
                            <Container>
                                <Button
                                    onClick={() => setSelectedPoll(null)}
                                    style={{ marginBottom: 10, marginTop: 10 }}
                                >
                                    Start Over
                                </Button>
                            </Container>
                        </Box>
                    </Grid>
                    {/* <Grid item xs={12}>
                        <Title level={3}>{selectedPoll.title}</Title>
                        <p>{selectedPoll.description}</p>
                    </Grid> */}
                    {voted ? (
                        <Grid item xs={12}>
                            <BannerTwo
                                title={"Thank You for Voting"}
                                message={
                                    "Your vote has been successfully submitted."
                                }
                            />

                            <Button
                                onClick={() => setSelectedPoll(null)}
                                style={{ marginBottom: 16 }}
                            >
                                Go Home
                            </Button>
                        </Grid>
                    ) : !userInfoSubmitted ? (
                        <Grid
                            item
                            xs={12}
                            md={10}
                            lg={6}
                            textAlign={"center"}
                            alignSelf={"space-around"}
                        >
                            <Paper elevation={16}>
                                <Card title="Your Information">
                                    <Form onFinish={handleUserInfoSubmit}>
                                        <Item
                                            name="name"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        "Please enter your name",
                                                },
                                                {
                                                    min: 2,
                                                    message:
                                                        "Name must be at least 2 characters long",
                                                },
                                            ]}
                                        >
                                            <Input
                                                prefix={<UserOutlined />}
                                                placeholder="Name"
                                                value={userInfo.name}
                                                onChange={(e) =>
                                                    setUserInfo({
                                                        ...userInfo,
                                                        name: e.target.value,
                                                    })
                                                }
                                                style={{
                                                    marginBottom: 10,
                                                    padding: 10,
                                                }}
                                            />
                                        </Item>
                                        <Item
                                            name="email"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        "Please enter your email",
                                                },
                                                {
                                                    type: "email",
                                                    message:
                                                        "Please enter a valid email",
                                                },
                                            ]}
                                        >
                                            <Input
                                                prefix={<MailOutlined />}
                                                placeholder="Email"
                                                value={userInfo.email}
                                                onChange={(e) =>
                                                    setUserInfo({
                                                        ...userInfo,
                                                        email: e.target.value,
                                                    })
                                                }
                                                style={{
                                                    marginBottom: 10,
                                                    padding: 10,
                                                }}
                                            />
                                        </Item>
                                        <Item
                                            name="phone"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        "Please enter your phone number",
                                                },
                                                {
                                                    pattern: /\d{2,15}/,
                                                    message:
                                                        "Please enter a valid phone number with country code",
                                                },
                                            ]}
                                        >
                                            <PhoneInput
                                                country={"bi"}
                                                value={userInfo.phone}
                                                onChange={(phone) =>
                                                    setUserInfo({
                                                        ...userInfo,
                                                        phone,
                                                    })
                                                }
                                                inputStyle={{ width: "100%" }}
                                            />
                                        </Item>
                                        <Checkbox
                                            checked={userInfo.agreeToContact}
                                            onChange={(e) =>
                                                setUserInfo({
                                                    ...userInfo,
                                                    agreeToContact:
                                                        e.target.checked,
                                                })
                                            }
                                        >
                                            I agree to be contacted by Buja
                                            Music Awards.
                                        </Checkbox>
                                        <Grid
                                            item
                                            xs={12}
                                            justifyContent={"space-around"}
                                        >
                                            <Box
                                                sx={{
                                                    py: 1,
                                                    bgcolor: "secondary.light",
                                                    color: "primary.contrastText",
                                                    textAlign: "center",
                                                }}
                                            >
                                                <Container>
                                                    <Button
                                                        type="success"
                                                        htmlType="submit"
                                                        style={{
                                                            //backgroundColor: "gold",
                                                            color: "black",
                                                        }}
                                                    >
                                                        Submit
                                                    </Button>
                                                </Container>
                                            </Box>
                                        </Grid>
                                    </Form>
                                </Card>
                            </Paper>
                        </Grid>
                    ) : (
                        <Grid
                            item
                            xs={12}
                            md={10}
                            lg={6}
                            alignSelf={"space-around"}
                        >
                            <Paper elevation={16}>
                                <Box>
                                    <Card
                                        title={
                                            selectedPoll.questions[
                                                currentQuestionIndex
                                            ].text
                                        }
                                    >
                                        <Radio.Group
                                            onChange={(e) =>
                                                handleOptionChange(
                                                    selectedPoll.questions[
                                                        currentQuestionIndex
                                                    ]._id,
                                                    e.target.value
                                                )
                                            }
                                            value={
                                                selectedOptions[
                                                    selectedPoll.questions[
                                                        currentQuestionIndex
                                                    ]._id
                                                ]
                                            }
                                        >
                                            {selectedPoll.questions[
                                                currentQuestionIndex
                                            ].options.map((option) => (
                                                <Box
                                                    style={{
                                                        marginBottom: 10,
                                                        width: "100%",
                                                    }}
                                                >
                                                    <Radio
                                                        key={option._id}
                                                        value={option._id}
                                                    >
                                                        {option.text}
                                                    </Radio>
                                                    {/* <Divider /> */}
                                                </Box>
                                            ))}
                                        </Radio.Group>
                                    </Card>
                                    <Grid
                                        item
                                        xs={12}
                                        justifyContent={"space-around"}
                                    >
                                        <Box
                                            sx={{
                                                py: 1,
                                                bgcolor: "secondary.light",
                                                color: "primary.contrastText",
                                                textAlign: "center",
                                            }}
                                        >
                                            <Container>
                                                <div style={{ marginTop: 16 }}>
                                                    {currentQuestionIndex >
                                                        0 && (
                                                        <Button
                                                            style={{
                                                                marginRight: 8,
                                                            }}
                                                            onClick={
                                                                handlePreviousQuestion
                                                            }
                                                        >
                                                            Back
                                                        </Button>
                                                    )}
                                                    {currentQuestionIndex <
                                                    selectedPoll.questions
                                                        .length -
                                                        1 ? (
                                                        <Button
                                                            type="primary"
                                                            onClick={
                                                                handleNextQuestion
                                                            }
                                                        >
                                                            Next
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            type="primary"
                                                            loading={loading}
                                                            onClick={
                                                                handleSubmitPoll
                                                            }
                                                            style={{
                                                                backgroundColor:
                                                                    "gold",
                                                            }}
                                                        >
                                                            Submit
                                                        </Button>
                                                    )}
                                                </div>
                                            </Container>
                                        </Box>
                                    </Grid>
                                </Box>
                            </Paper>
                        </Grid>
                    )}
                </Grid>
            )}
            <PrimaryStrip />
            <GetInvolved />
        </Grid>
    );
};

export default Poll;
