import React from "react";
import { createTheme } from "@mui/material/styles";
import { grey } from "@mui/material/colors";

const theme = createTheme({
    palette: {
        primary: {
            dark: "#040619",
            main: "#070924", // A deep blue color for the primary theme
            light: "#383a4f",
        },
        secondary: {
            dark: "#8e721c",
            main: "#cba328", // A pink color for the secondary theme
            light: "#d5b553",
        },
        background: {
            //default: "#F5F5F5", // Light gray background color
            default: grey[300], // Light gray background color
        },
    },
    // typography: {
    //     fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,

    //     // fontFamily: "Roboto",
    //     fontWeightLight: 300,
    //     fontWeightRegular: 400,
    //     fontWeightMedium: 500,
    // },
    typography: {
        h1: {
            fontSize: "2rem",
            "@media (min-width:600px)": {
                fontSize: "2.5rem",
            },
            "@media (min-width:960px)": {
                fontSize: "3rem",
            },
            "@media (min-width:1280px)": {
                fontSize: "3.5rem",
            },
        },
        h2: {
            fontSize: "1.75rem",
            "@media (min-width:600px)": {
                fontSize: "2.25rem",
            },
            "@media (min-width:960px)": {
                fontSize: "2.75rem",
            },
            "@media (min-width:1280px)": {
                fontSize: "3.25rem",
            },
        },
        h3: {
            fontSize: "1.5rem",
            "@media (min-width:600px)": {
                fontSize: "2rem",
            },
            "@media (min-width:960px)": {
                fontSize: "2.5rem",
            },
            "@media (min-width:1280px)": {
                fontSize: "3rem",
            },
        },
        h4: {
            fontSize: "1.25rem",
            "@media (min-width:600px)": {
                fontSize: "1.75rem",
            },
            "@media (min-width:960px)": {
                fontSize: "2.25rem",
            },
            "@media (min-width:1280px)": {
                fontSize: "2.75rem",
            },
        },
        h5: {
            fontSize: "1rem",
            "@media (min-width:600px)": {
                fontSize: "1.5rem",
            },
            "@media (min-width:960px)": {
                fontSize: "2rem",
            },
            "@media (min-width:1280px)": {
                fontSize: "2.5rem",
            },
        },
        body1: {
            fontSize: "1rem",
            "@media (min-width:600px)": {
                fontSize: "1.2rem",
            },
            "@media (min-width:960px)": {
                fontSize: "1.5rem",
            },
            "@media (min-width:1280px)": {
                fontSize: "1.8rem",
            },
        },
        body2: {
            fontSize: "0.875rem",
            "@media (min-width:600px)": {
                fontSize: "1rem",
            },
            "@media (min-width:960px)": {
                fontSize: "1.25rem",
            },
            "@media (min-width:1280px)": {
                fontSize: "1.5rem",
            },
        },
        subtitle1: {
            fontSize: "1rem",
            "@media (min-width:600px)": {
                fontSize: "1.2rem",
            },
            "@media (min-width:960px)": {
                fontSize: "1.4rem",
            },
            "@media (min-width:1280px)": {
                fontSize: "1.6rem",
            },
        },
        subtitle2: {
            fontSize: "0.875rem",
            "@media (min-width:600px)": {
                fontSize: "1rem",
            },
            "@media (min-width:960px)": {
                fontSize: "1.125rem",
            },
            "@media (min-width:1280px)": {
                fontSize: "1.25rem",
            },
        },
    },
});

// theme.typography.body1 = {
//     fontSize: "1.2rem",
//     fontWeight: 100,
//     lineHeight: 1.4,
//     [theme.breakpoints.down("sm")]: {
//         fontSize: "1rem",
//     },
//     [theme.breakpoints.up("md")]: {
//         fontSize: "1.5rem",
//     },
// };

// theme.typography.body2 = {
//     fontSize: "1.2rem",
//     fontWeight: 100,
//     lineHeight: 1.4,
//     [theme.breakpoints.down("sm")]: {
//         fontSize: "0.8rem",
//     },
//     [theme.breakpoints.up("sm")]: {
//         fontSize: "1rem",
//     },
// };

// theme.typography.subtitle1 = {
//     fontSize: "1.2rem",
//     fontWeight: 100,
//     lineHeight: 1.4,
//     [theme.breakpoints.down("sm")]: {
//         fontSize: "1rem",
//     },
//     [theme.breakpoints.up("md")]: {
//         fontSize: "1.5rem",
//     },
// };
// theme.typography.subtitle2 = {
//     fontSize: "1.2rem",
//     fontWeight: 100,
//     lineHeight: 0,
//     [theme.breakpoints.down("sm")]: {
//         fontSize: "0.8rem",
//     },
//     [theme.breakpoints.up("sm")]: {
//         fontSize: "0.9rem",
//     },
// };

// theme.typography.h6 = {
//     fontSize: "1.2rem",
//     lineHeight: 2,
//     fontWeight: 500,

//     [theme.breakpoints.down("sm")]: {
//         fontSize: "1.3rem",
//     },
//     [theme.breakpoints.up("sm")]: {
//         fontSize: "1.5rem",
//     },
// };

// theme.typography.h5 = {
//     lineHeight: 1.1,
//     fontWeight: 400,
//     fontSize: "1.1rem",

//     [theme.breakpoints.down("md")]: {
//         fontSize: "2rem",
//     },

//     [theme.breakpoints.up("md")]: {
//         fontSize: "2.5rem",
//     },
// };

// theme.typography.h2 = {
//     lineHeight: 1.4,
//     fontWeight: 500,
//     fontSize: "1.1rem",

//     [theme.breakpoints.down("sm")]: {
//         fontSize: "2rem",
//     },
//     [theme.breakpoints.up("sm")]: {
//         fontSize: "2.5rem",
//     },
//     [theme.breakpoints.up("md")]: {
//         fontSize: "3rem",
//     },
//     [theme.breakpoints.up("lg")]: {
//         fontSize: "4rem",
//     },
// };

export default theme;
