import React, { useEffect, useState } from "react";
import {
    Box,
    Container,
    Grid,
    Typography,
    IconButton,
    Paper,
    CircularProgress,
} from "@mui/material";
import { Facebook, Twitter, Instagram, LinkedIn } from "@mui/icons-material";
import logo from "../../img/logo.webp";
import { Phone } from "@mui/icons-material";
import { Email } from "@mui/icons-material";
import { LocationOn } from "@mui/icons-material";
import { SocialMedia } from "./SocialMedia";
import axios from "axios";
import { Link } from "react-router-dom";

const API_URL = process.env.REACT_APP_API_URL;

const Footer = () => {
    const [footer, setfooter] = useState({});
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchContent = async () => {
            try {
                const response = await axios.get(`${API_URL}/api/footer`);
                setfooter(response.data[0]);
            } catch (err) {
                setError(true);
            } finally {
                setLoading(false);
            }
        };

        fetchContent();
    }, []);

    if (loading) {
        return <CircularProgress />;
    }

    if (error) {
        return (
            <Typography variant="h6" color="error">
                Failed to load data: {error.message}
            </Typography>
        );
    }

    return (
        <Paper
            component="footer"
            sx={{
                py: 4,
                mt: 2,
                backgroundColor: "primary.dark",
                color: "white",

                borderTopRightRadius: "30%",
            }}
        >
            <Container maxWidth="lg">
                <Grid container spacing={3} justifyContent={"space-around"}>
                    {/* <Grid item xs={12} md={2}>
                        <Typography variant="h6" gutterBottom>
                            {footer.sections[0].title}
                        </Typography>
                        {footer.sections[0].links.map((link) => (
                            <Box justifyContent="center">
                                <Link
                                    to={link.link}
                                    color="inherit"
                                    display="block"
                                    mb={1}
                                >
                                    {link.label};
                                </Link>
                            </Box>
                        ))}
                    </Grid> */}
                    <Grid item xs={12} md={6}>
                        <Box display="flex" justifyContent="center" mb={2}>
                            <img
                                src={`${API_URL}${footer.logoUrl}`}
                                alt="Buja Music Awards"
                                style={{ width: "150px" }}
                            />
                        </Box>
                        <Typography variant="body2" align="center">
                            Buja Music Awards celebrates the best in the music
                            industry. Join us to celebrate the achievements of
                            talented artists.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} textAlign={"center"}>
                        <Typography variant="h6" gutterBottom align="center">
                            {footer.sections[2].title}
                        </Typography>

                        <Typography variant="subtitle2">
                            <Phone /> {footer.sections[2].contactInfo.phone}
                        </Typography>
                        <Typography variant="subtitle2">
                            <Email /> {footer.sections[2].contactInfo.email}
                        </Typography>
                        <Typography variant="subtitle2">
                            <LocationOn />{" "}
                            {footer.sections[2].contactInfo.address}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <SocialMedia socialLinks={footer.sections[1]} />
                    </Grid>
                </Grid>
                <Box textAlign="center" mt={4}>
                    <Typography variant="body2">
                        © 2024 Buja Music Awards. All Rights Reserved.
                    </Typography>
                </Box>
            </Container>
        </Paper>
    );
};

export default Footer;
