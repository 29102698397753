import React, { useEffect, useState } from "react";
import axios from "axios";
import { Typography } from "antd";
import AboutPageHero from "../components/common/AboutPageHero";
import {
    Messaging,
    OurMission,
    YourParticipation,
} from "../components/common/Components";
import { Card, CardContent, CardMedia, CircularProgress } from "@mui/material";

const { Title, Paragraph } = Typography;

const APP_URL = process.env.REACT_APP_API_URL;

function About() {
    const [AboutUs, setAboutUs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchContent = async () => {
            try {
                const response = await axios.get(`${APP_URL}/api/aboutus`);
                setAboutUs(response.data[0]);
            } catch (err) {
                setError(true);
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        fetchContent();
    }, []);

    if (loading) {
        return <CircularProgress />;
    }

    if (error) {
        return (
            <Typography variant="h6" color="error">
                Failed to load data: {error.message}
            </Typography>
        );
    }

    return (
        <>
            <AboutPageHero />
            {/*<OurMission /> */}
            <YourParticipation />
            <HeroSection
                heroTitle={AboutUs.title}
                HeroMessage={AboutUs.message}
            />
            <Messaging title={"Buja Music Awards"} message={AboutUs.message} />
            <Messaging
                title={"Our Misson"}
                message={AboutUs.mission.overview}
            />
            <Messaging
                title={AboutUs.mission.objectives[2].title}
                message={AboutUs.mission.objectives[2].message}
            />
        </>
    );
}

function HeroSection({ heroTitle, HeroMessage, heroBackgroundImageUrl }) {
    return (
        <Card>
            <CardMedia
                component="img"
                alt={heroTitle}
                height="400"
                image={heroBackgroundImageUrl}
                title={heroTitle}
            />
            <CardContent>
                <Typography variant="h2" component="div">
                    {heroTitle}
                </Typography>
                <Typography variant="h5" color="textSecondary">
                    {HeroMessage}
                </Typography>
            </CardContent>
        </Card>
    );
}
export default About;
