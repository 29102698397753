const AppRoutes = [
    {
        path: "/",
        label: "Home",
        mainMenu: true,
        mobileMenu: true,
        secured: false,
    },
    // {
    //     path: "/Ussd",
    //     label: "USSD Vote",
    //     mainMenu: true,
    //     mobileMenu: true,
    //     secured: false,
    // },
    // {
    //     path: "/News",
    //     label: "News",
    //     mainMenu: true,
    //     mobileMenu: true,
    //     secured: false,
    // },
    // {
    //     path: "/about",
    //     label: "About",
    //     mainMenu: true,
    //     mobileMenu: true,
    //     secured: false,
    // },
    // {
    //     path: "/Artists",
    //     label: "Artists",
    //     mainMenu: true,
    //     mobileMenu: true,
    //     secured: false,
    // },
    // {
    //     path: "/Winners/",
    //     label: "Winners",
    //     mainMenu: true,
    //     mobileMenu: true,
    //     secured: false,
    // },

    {
        path: "/vote",
        label: "Vote",
        mainMenu: true,
        mobileMenu: true,
        secured: false,
    },
    {
        path: "/contact",
        label: "Contact",
        mainMenu: true,
        mobileMenu: true,
        secured: false,
    },
    {
        path: "*",
        label: "Not Found",
        mainMenu: false,
        mobileMenu: false,
        secured: false,
    },
];

export default AppRoutes;
