import React from "react";

import { Layout, Menu, Row } from "antd";
import MyFooter from "./common/Footer";
import NavBar from "./common/NavBar";

const { Content } = Layout;

function AppLayout({ children }) {
    return (
        <Layout>
            <NavBar />
            <Content>{children}</Content>
            <MyFooter />
        </Layout>
    );
}

export default AppLayout;
