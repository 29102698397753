import React, { useState } from "react";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const Ussd = () => {
    const [phoneNumber, setPhoneNumber] = useState("");
    const [sessionId, setSessionId] = useState("");
    const [serviceCode, setServiceCode] = useState("");
    const [text, setText] = useState("");
    const [response, setResponse] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const res = await axios.post(`${API_URL}/api/ussd`, {
                phoneNumber,
                sessionId,
                serviceCode,
                text,
            });
            setResponse(res.data);
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <div>
            <h1>USSD Voting System</h1>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Phone Number:</label>
                    <input
                        type="text"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                </div>
                <div>
                    <label>Session ID:</label>
                    <input
                        type="text"
                        value={sessionId}
                        onChange={(e) => setSessionId(e.target.value)}
                    />
                </div>
                <div>
                    <label>Service Code:</label>
                    <input
                        type="text"
                        value={serviceCode}
                        onChange={(e) => setServiceCode(e.target.value)}
                    />
                </div>
                <div>
                    <label>Text:</label>
                    <input
                        type="text"
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                    />
                </div>
                <button type="submit">Send</button>
            </form>
            <div>
                <h2>Response</h2>
                <pre>{response}</pre>
            </div>
        </div>
    );
};

export default Ussd;
