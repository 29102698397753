import React, { useEffect, useState } from "react";

import {
    Box,
    Button,
    CardContent,
    CardMedia,
    Container,
    Divider,
    Grid,
    Paper,
    Typography,
} from "@mui/material";
import { Avatar, Card } from "antd";

import feature2Image from "../../img/hero.png";
import feature1Image from "../../img/hero2.png";
import feature3Image from "../../img/hero3.png";
import axios from "axios";
import { Link } from "react-router-dom";

const APP_URL = process.env.REACT_APP_API_URL;

export const OurMission = () => {
    return (
        <Container sx={{ marginTop: 5, marginBottom: 3, zIndex: 100 }}>
            <Paper
                elevation={24}
                sx={{
                    padding: 3,
                    //textAlign: "center",
                    backgroundColor: "background.default",
                    color: "primary.main",
                }}
            >
                <Typography variant="h6" gutterBottom color="primary.dark">
                    Our Mission
                </Typography>
                <Grid item>
                    <Typography variant="body1" paragraph>
                        Our mission is to promote excellence, foster creativity,
                        and unite music enthusiasts in a joyous celebration of
                        our musical heritage. From iconic performances to
                        unforgettable collaborations, the Buja Music Awards is
                        more than an event – it's a cultural spectacle that
                        brings people together through the universal language of
                        music.
                    </Typography>
                </Grid>
            </Paper>
        </Container>
    );
};

export const YourParticipation = () => {
    return (
        <Container sx={{ marginTop: 5, marginBottom: 3, zIndex: 100 }}>
            <Paper
                elevation={24}
                sx={{
                    padding: 3,
                    //textAlign: "center",
                    backgroundColor: "background.default",
                    color: "primary.main",
                }}
            >
                <Typography variant="h6" gutterBottom color="primary.dark">
                    Your Participation
                </Typography>
                <Grid item>
                    <Typography>
                        Join us on this incredible journey as we continue to
                        elevate and celebrate the remarkable achievements of our
                        musicians. Let the harmony of voices and rhythms
                        resonate, echoing the spirit of Burundian music across
                        borders.
                    </Typography>
                </Grid>
            </Paper>
        </Container>
    );
};

export const Messaging = (props) => {
    return (
        <Container sx={{ marginTop: 5, marginBottom: 3, zIndex: 100 }}>
            <Paper
                elevation={24}
                sx={{
                    padding: 3,
                    //textAlign: "center",
                    backgroundColor: "background.default",
                    color: "primary.main",
                }}
            >
                <Typography variant="h6" gutterBottom color="primary.dark">
                    {props.title}
                </Typography>
                <Grid item>
                    <Typography>{props.message}</Typography>
                </Grid>
            </Paper>
        </Container>
    );
};

export const Features = () => {
    const content = [
        {
            title: " Celebrating Excellence in Music",
            description:
                "At the Buja Music Awards, we recognize and celebrate the outstanding talents and contributions of artists across diverse musical genres. Our awards are a tribute to the dedication, passion, and creativity of musicians who push the boundaries of what’s possible in music. From soulful ballads to electrifying performances, we honor those who elevate the art of music and inspire us all.",
        },
        {
            title: "Diverse Categories for Every Talent",
            description:
                "The Buja Music Awards encompass a wide range of categories, ensuring that every unique musical style and contribution is acknowledged. From Best New Artist to Lifetime Achievement Awards, our categories cover all aspects of musical excellence. We are committed to inclusivity, recognizing talents from emerging artists to seasoned professionals, and celebrating the diverse musical landscape that enriches our culture.",
        },
    ];
    return (
        <Box sx={{ py: 8, bgcolor: "background.paper" }}>
            <Container>
                <Typography
                    variant="h4"
                    component="h2"
                    gutterBottom
                    align="center"
                >
                    Our Goals
                </Typography>
                <Grid container spacing={4}>
                    {content.map((feature, index) => (
                        <Grid item xs={12} md={6} key={index}>
                            <Card>
                                <img
                                    src={feature1Image}
                                    alt="Feature 1"
                                    style={{ width: "100%" }}
                                />
                                <CardContent>
                                    <Typography
                                        variant="h6"
                                        component="h6"
                                        gutterBottom
                                    >
                                        {feature.title}
                                    </Typography>
                                    <Typography variant="body2" component="p">
                                        {feature.description}
                                    </Typography>
                                </CardContent>
                            </Card>
                            ;
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Box>
    );
};

export const Testimonials = () => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const [testimonials, setTestimonials] = useState([]);

    useEffect(() => {
        const fetchContent = async () => {
            try {
                const response = await axios.get(`${APP_URL}/api/testimonials`);
                setTestimonials(response.data);
            } catch (err) {
                setError(true);
                console.error(err);
            } finally {
                setLoading(false);
            }
        };
        fetchContent();
    }, []);

    if (loading) {
        return <>loading</>;
    }
    if (error) {
        return <>Error occurred</>;
    }

    return (
        /* Testimonials Section */
        <Box sx={{ py: 8, bgcolor: "grey.100" }}>
            <Container>
                <Typography
                    variant="h4"
                    component="h2"
                    gutterBottom
                    align="center"
                >
                    Testimonials
                </Typography>
                <Grid container spacing={4}>
                    {testimonials.map((testimony, index) => (
                        <Grid item xs={12} md={4} key={index}>
                            <Card>
                                <CardContent>
                                    <Typography
                                        variant="h6"
                                        component="h3"
                                        gutterBottom
                                    >
                                        {testimony.author}
                                    </Typography>
                                    <Typography variant="body1" component="p">
                                        {testimony.content}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Box>
    );
};

export const CallToAction = (props) => {
    return (
        /* Call to Action Section */
        <Box
            sx={{
                py: 8,
                bgcolor: "primary.main",
                color: "primary.contrastText",
                textAlign: "center",
            }}
        >
            <Container>
                <Typography variant="h4" component="h2" gutterBottom>
                    {props.title}
                </Typography>
                <Typography variant="body1" component="p" gutterBottom>
                    {props.submessage}
                </Typography>
                {props.link ? (
                    <Button variant="contained" color="secondary" size="large">
                        {props.link}
                    </Button>
                ) : null}
            </Container>
        </Box>
    );
};

export const BujaMusicAwards = () => {
    const data = {
        title: "Be Part of the Celebration",
        description:
            "Join us in celebrating the best in music by becoming a part of the Buja Music Awards. Whether you're an artist, a sponsor, or a music lover, there's a place for you in our vibrant community. Learn how to nominate artists, become a sponsor, or simply enjoy the show. Together, we can make the Buja Music Awards an unforgettable celebration of musical brilliance and community spirit.",
    };

    return (
        <Box
            sx={{
                py: 8,
                bgcolor: "primary.main",
                color: "primary.contrastText",
                textAlign: "center",
            }}
        >
            <Container>
                <Typography variant="h6" component="h6" gutterBottom>
                    {data.title}
                </Typography>
                <Typography variant="body2" component="p" gutterBottom>
                    {data.description}
                </Typography>
            </Container>
        </Box>
    );
};

{
    /* Upcoming Events */
}
export const UpcomingEvents = () => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [events, setEvents] = useState([]);

    useEffect(() => {
        const fetchContent = async () => {
            try {
                const response = await axios.get(`${APP_URL}/api/events`);
                setEvents(response.data);
            } catch (err) {
                setError(true);
                console.error(err);
            } finally {
                setLoading(false);
            }
        };
        fetchContent();
    }, []);

    if (loading) {
        return <>loading</>;
    }
    if (error) {
        return <>Error occurred</>;
    }
    return (
        <Box>
            {console.log(events)}
            <Container>
                <Grid container>
                    <Grid item sx={12}>
                        <Typography variant="h4" component="h2" gutterBottom>
                            Upcoming Events
                        </Typography>
                    </Grid>

                    <Grid container sx={12} sm={12}>
                        {events.map((event) => (
                            <Grid item sx={12} sm={6}>
                                <Typography variant="h6" gutterBottom>
                                    {event.title}
                                </Typography>
                                <Typography variant="body1" paragraph>
                                    {event.about}
                                </Typography>

                                {event.highlights.map((item) => (
                                    <Typography variant="body1">
                                        {item}
                                    </Typography>
                                ))}

                                <Typography variant="subtitle">
                                    {event.date}
                                </Typography>
                                {/* Countdown Timer */}
                                <Box mt={2}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        to="/events"
                                    >
                                        Learn More
                                    </Button>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>

                    <Divider />
                </Grid>
            </Container>
        </Box>
    );
};

{
    /* Featured Artists */
}
export const FeaturedArtists = () => {
    return (
        <Box my={4}>
            <Typography variant="h4" component="h2" gutterBottom>
                Featured Artists
            </Typography>
            <Grid container spacing={4}>
                {/* Example of one artist card */}
                <Grid item xs={12} sm={6} md={4}>
                    <Card>
                        <CardMedia
                            component="img"
                            alt="Artist Name"
                            height="200"
                            image="/path/to/artist-image.jpg"
                        />
                        <CardContent>
                            <Typography variant="h5" component="div">
                                Artist Name
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Short bio of the artist.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                {/* Repeat for other artists */}
            </Grid>
        </Box>
    );
};

{
    /* Award Categories */
}
export const AwardCategories = () => {
    return (
        <Box my={4}>
            <Typography variant="h4" component="h2" gutterBottom>
                Award Categories
            </Typography>
            <Grid container spacing={4}>
                {/* Example of one category card */}
                <Grid item xs={12} sm={6} md={4}>
                    <Card>
                        <CardContent>
                            <Typography variant="h5" component="div">
                                Best New Artist
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Recognizing the most promising new talent.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                {/* Repeat for other categories */}
            </Grid>
        </Box>
    );
};

{
    /* Recent News */
}
export const RecentNews = () => {
    return (
        <Box my={4}>
            <Typography variant="h4" component="h2" gutterBottom>
                Recent News
            </Typography>
            <Grid container spacing={4}>
                {/* Example of one news card */}
                <Grid item xs={12} sm={6} md={4}>
                    <Card>
                        <CardMedia
                            component="img"
                            alt="News Title"
                            height="140"
                            image="/path/to/news-image.jpg"
                        />
                        <CardContent>
                            <Typography variant="h5" component="div">
                                News Title
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Brief snippet of the news content.
                            </Typography>
                            <Box mt={2}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    to="/news"
                                >
                                    Read More
                                </Button>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
                {/* Repeat for other news articles */}
            </Grid>
        </Box>
    );
};

{
    /* Get Involved */
}
export const GetInvolved = () => {
    return (
        <Grid
            container
            backgroundColor={"secondary.light"}
            textAlign={"center"}
            padding={10}
        >
            <Container
                style={{
                    alignContent: "center",
                }}
            >
                <Typography variant="h6" gutterBottom>
                    Become a Sponsor or Volunteer
                </Typography>
                <Typography variant="body1" paragraph>
                    Learn how you can support the Buja Music Awards by becoming
                    a sponsor or volunteer. Together, we can make this event a
                    grand success.
                </Typography>
                <Box margin={2} textAlign={"center"}>
                    <Link to="/Contact">
                        <Button variant="contained" color="primary">
                            Contact us
                        </Button>
                    </Link>
                </Box>
            </Container>
        </Grid>
    );
};

export const PrimaryStrip = () => {
    return (
        <Grid
            xs={12}
            sx={{
                py: 1,
                bgcolor: "primary.main",
                color: "primary.contrastText",
                textAlign: "center",
            }}
        ></Grid>
    );
};
export const SecondaryStrip = () => {
    return (
        <Grid
            xs={12}
            sx={{
                py: 1,
                bgcolor: "secondary.main",
                color: "primary.contrastText",
                textAlign: "center",
            }}
        ></Grid>
    );
};
export const BannerTwo = ({ title, message }) => {
    return (
        <Box
            sx={{
                py: 8,
                bgcolor: "primary.main",
                color: "primary.contrastText",
                textAlign: "center",
            }}
        >
            <Container>
                <Typography variant="h6" component="h6" gutterBottom>
                    {title}
                </Typography>
                <Typography variant="body1" component="p" gutterBottom>
                    {message}
                </Typography>
            </Container>
        </Box>
    );
};

export const Banner = ({ title, message }) => {
    return (
        <Grid
            container
            backgroundColor={"secondary.light"}
            textAlign={"center"}
            padding={10}
        >
            <Container
                style={{
                    alignContent: "center",
                }}
            >
                <Typography variant="h6" gutterBottom>
                    {title}
                </Typography>
                <Typography variant="body1" paragraph>
                    {message}
                </Typography>
            </Container>
        </Grid>
    );
};
