import React from "react";
import { Layout, Carousel, Card, Row, Col } from "antd";
// import bma from "../../img/bujamusicawards.png";
import bma1 from "../../img/1.png";
import bma2 from "../../img/2.png";
import bma3 from "../../img/3.png";
import Paragraph from "antd/es/skeleton/Paragraph";
import Title from "antd/es/skeleton/Title";
import img from "../../img/nayeon-2024-jype.jpg";
import { FacebookFilled, InstagramFilled } from "@ant-design/icons";
const { Content } = Layout;

// const HeroSection = () => {
//     return (
//         <Carousel autoplay>
//             <img
//                 src={bma1}
//                 alt="Hero Image 1"
//                 // style={{ width: "100%", maxHeight: "100%" }}
//             />
//             <img
//                 src={bma2}
//                 alt="Hero Image 2"
//                 style={
//                     {
//                         // width: "100%",
//                         // maxHeight: "100%",
//                         // minHeight: "300px",
//                     }
//                 }
//             />
//             {/* Add more slides as needed */}
//         </Carousel>
//     );
// };

const HeroSection = () => {
    const imageStyle = {
        // height: '100%',
        backgroundSize: "cover",
        borderRadius: "5px",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "50%",
    };
    return (
        <Content style={{ minHeight: "20vw", background: "gold" }}>
            <Row>
                <Col xs={24}>
                    <div>
                        <h1 type="Primary">Buja Music Awards</h1>
                        <h6>Buja Music Awards</h6>
                        <img src={img} style={{ maxHeight: "40vw" }} />
                    </div>
                </Col>
            </Row>
        </Content>
    );
};

export default HeroSection;
